@import '../../variables.scss';

.external-action-confirm, .external-action-explanation {
  padding: 10px;
  color: $dark-color;

  .continue-button {
    margin-top: 20px;
  }

  .checkbox-announce {
    padding: 10px 0;
  }
}



.confirm-question {
  font-size: 20px;
  color: var(--secondary-color);
}
