@import '../../variables.scss';

.post-title {
  margin-top: 8px;
}

.post-description {
  margin-top: 8px !important;
  margin-bottom: 16px !important;
}

.updated-at {
  display: flex;
  justify-content: flex-end;
}

.post-html {
  overflow-x: scroll;
  img {
    height: auto !important;
  }

  p {
    margin-bottom: 20px;
  }
}

.view-post {
  color: $dark-color;
}

.kaltura-movie {
  width: 100%;
  > div {
    width: 100% !important;
  }
}
