@import '../../variables.scss';

.more-screen {
  background: $more-bg;
  color: #4a4a4a;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 58px;
  z-index: $z-index-more-screen;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

  .more-screen-header {
    background: white;
    padding: 20px;

    .header-avatar {
      display: block;
      margin: 0 auto;
      border-radius: 50%;
      width: 64px;
      margin-bottom: 16px;
    }

    .button {
      padding: 3px 17px;
      height: 34px;
      line-height: 30px;
    }
  }

  .stores-links {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .store-links-info {
      width: 100%;
      margin: 0px 0px 16px 0px;
    }
  }

  .small-rankings-list {
    display: flex;
    padding: 0 10px;
    justify-content: space-between;
    margin-top: 16px;

    // > span, > div {
    //   flex: 1;
    // }

    .title {
      color: #8f9194;
    }
  }
    .small-ranking {
      .ranking-img {
        border-radius: 50%;
        margin-right: 5px;
      }
    }


  .more-screen-body {
    padding: 16px;

    .body-title {
      color: var(--secondary-color);
      font-size: 24px;
      font-weight: var(--bold);
    }

    & > a,
    & > button {
      display: flex !important;
      justify-content: flex-start;
      align-items: center;
    }

    a, button {
      display: block;
      font-size: 16px;
      text-decoration: none;
      padding: 7px 0;
      font-weight: var(--bold);
      color: var(--text-color);

      i {
        font-size: 24px;
        margin-right: 10px;
        color: var(--text-color);
        font-weight: normal;
      }

      &:hover {
        color: var(--secondary-color);

        svg {
          &, path {
            color: var(--secondary-color);
          }
        }
      }
    }

    .menu-button {
      border: none;
      background: none;
      cursor: pointer;
    }
  }

  .more-screen-footer {
    padding: 32px 0px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      width: max-content;
      font-size: 16px;
    }

    img {
      width: 56px;
      margin: 0px 4px;
    }
  }
