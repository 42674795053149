.poll {
  margin-bottom: 15px;

  h3 {
    margin-bottom: 15px;
  }

  .button {
    margin: 5px;
  }

  p {
    color: #ffffff !important;
    font-weight: bold !important;
  }
}
