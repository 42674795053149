@import '../../variables.scss';

h2 {
  width: 100%;
  text-align: start;
  margin-bottom: 8px !important;
}

.social-share {
  display: flex;

  button.button {
    width: 42px;
    height: 42px;
    margin-bottom: 4px;

    i {
      font-size: 16px;
      margin: 0px;
    }
  }

  > div {
    flex: 1;
    margin: 8px 0px;
    margin-bottom: 16px;

    > h4 {
      margin-top: 0;
    }
  }
}
