@import '../../variables.scss';

.default-link {
  color: var(--secondary-color);
  font-size: 16px;

  &:hover {
    text-decoration: underline;
    color: #888;
  }
}

.empty-list {
  margin-top: 32px;
}
