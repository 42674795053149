@import '../../../variables.scss';

.input {
  display: block;
  width: 100%;
  padding: 5px 10px;
  margin: 10px 0;
  font-family: inherit;
  color: $text-color;
  border: solid 1px $input-border-color;
  background: #fff;
  border-radius: 5px;
  -webkit-appearance: textfield;
  transition: all 400ms;

  &:focus {
    outline: none;
    color: var(--secondary-color);
    border-color: var(--secondary-color);
  }

  &.invalid {
    color: $danger-color;
    border-color: $danger-color;
    background: lighten($danger-color, 45%);
  }

  &.small {
    display: inline-block;
    width: auto;
  }
}
