@import '../variables.scss';

@font-face {
  font-family: 'CircularStd';
  src: url('../assets/fonts/Circular-Std-Medium.ttf');
}

:root {
  --bold: 600;
  --normal: 400;
  --thin: 100;
}

:focus {
  outline: none;
}

html,
body,
#root,
.app {
  height: 100%;
}

html {
  font-size: $font-size-base;
}

body {
  font-family: $font-family;
  color: $text-color;
  line-height: 1.5;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

form {
  &[disabled] {
    button {
      opacity: 0.4;
    }
  }
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

h3 {
  font-weight: var(--bold)
}

h1, h2, h3, h4, h5, h6 {
  margin: 0px;
}

p {
  margin: 0;
}

b, strong {
  font-weight: var(--bold)
}

.thin {
  font-weight: var(--thin)
}

.hr {
  border: none;
  margin: 20px 0;
  border-bottom: 1px solid $gray;
}

.main {
  padding-bottom: 1px;
}

.no-button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.default-scroll::-webkit-scrollbar-track {
  background-color: var(--background-placeholder-color);
  background: #0000;
  border-radius: 4px;
}
.default-scroll::-webkit-scrollbar {
  width: 6px;
  height: 2px;
  background: var(--background-placeholder-color);
  background: #0000;
  border-radius: 4px;
}
.default-scroll::-webkit-scrollbar-thumb {
  background: #00000030;
  border-radius: 4px;
}

.error-message {
  color: var(--failure-color);
  margin-top: 2px;
}

@media (max-width: 899px) {
  .hide-on-mobile {
    display: none;
  }

  .crisp-client {
    display: none;
  }
}
