@import '../../../variables.scss';

.header {
  width: 100%;
  min-height: 180px;
  max-height: 180px;
  padding: 30px 0;
  color: var(--primary-inverse-color);
  background: var(--primary-color);

  .logo {
    padding: 0px;
    width: 100%;
    max-width: 120px;
    margin-left: 32px;
  }

  & > .container {
    height: 120px;
  }

  .row {
    height: 100%;

    .col:first-child {
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.card-wrapper {
  position: relative;

  @media (min-width: $break-min) {
    margin: 0 50px;
  }

  @media (max-width: $break-max) {
    margin-top: 30px;
    margin-bottom: -300px;
  }
}

.public-card {
  padding: 30px !important;
  z-index: 1;

  @media (min-width: $break-min) {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .input {
    padding: 10px 15px;
  }

  &.no-input-margin {
    .input-group {
      input, select {
        margin: 0;
        height: 40px;
      }
    }
  }
}

@media (max-width: 720px) {
  .header .logo {
    margin-left: 0px;
  }

  & > .card {
    top: -32px;
  }
}
