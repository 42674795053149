@import '../../variables.scss';

.subtask {
  border-top: 4px solid var(--primary-color);
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

.questionnaire {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 42px;

  .card {
    margin: 16px 8px;
  }
}

.action-area {
  width: 100%;
  display: flex;
  justify-content: center;

  .answer-questionnaire {
    width: max-content;
  }
}


.default-link {
  color: var(--secondary-color);
  font-size: 16px;

  &:hover {
    text-decoration: underline;
    color: #888;
  }
}
