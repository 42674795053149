@import '../../variables.scss';

.tutorial-card {
  background: #f6f7fb;
}

.tutorial-content {
  margin: 30px 0 20px;
}

.title-holder {
  margin-top: -60px;

  h3 {
    font-size: 24px;
    margin-bottom: 0;
  }

  p {
    margin: 0;
    font-size: 18px;
  }
}

.tutorial-skip {
  border: none !important;
}

.no-overflow {
  overflow: initial !important;
}

.task-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.relative {
  position: relative;
}

.help-who-posted, .help-points, .help-time, .help-task-button, .help-task-content {
  position: absolute;
  color: white;
  z-index: 5000;
  text-align: left;

  .title {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: var(--normal);
    font-style: normal;
    font-stretch: normal;
    line-height: 1.11;
    letter-spacing: 1.6px;
  }

  p {
    margin-top: 5px;
    font-size: 14px;
    font-weight: var(--normal);
    font-style: italic;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: 0.5px;
  }
}

.help-who-posted {
  bottom: 100%;
  left: 22px;
  margin-bottom: -8px;
}

.mobile-version {
  .help-who-posted {
    img {
      width: 100px;
    }
  }

  .help-points {
    img {
      width: 104px;
    }
  }

  .help-time {
    img {
      width: 110px;
    }
  }

  .help-task-button {
    img {
      width: 110px;
    }
  }

  .help-task-content {
    > div {
      img {
        margin-left: 0;
        right: 180px;
        position: relative;
      }
    }
  }
}

.help-time {
  top: 100%;
  left: 26px;
  margin-top: -20px;
}

.help-task-button {
  top: 100%;
  text-align: right;
  margin-top: -16px;
  right: -14px;
}

.help-points {
  bottom: 100%;
  right: -10px;
  text-align: right;
  margin-bottom: -15px;
}

.help-task-content {
  top: 0;
  height: 100%;
  left: 100%;
  width: 270px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.explanation-close {
  position: absolute;
  z-index: 5001;
  width: 40px;
  height: 40px;
  right: -20px;
  top: -20px;
  color: $secondary-inverse;
  background: var(--secondary-color);
  border-radius: 50%;
  opacity: 1;

  span {
    position: relative;
    top: -3px;
  }

  &:hover {
    color: var(--background-inverse-color);
    background: var(--primary-color);
  }
}

.linha {
  position: absolute;
  top: -5%;
  height: 105%;
  left: -9px;
}

.texto-conteudo {
  margin-left: 48px;
}

.mobile-version {
  .action-explanation {
    margin-top: 120px;
  }
}

.welcome-image .to-fill {
  fill: var(--primary-color);
}
