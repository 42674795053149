@import '../../../variables.scss';

.upload-image {
  display: inline-block;
  padding: 15px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 100px;
    cursor: pointer;
    opacity: 0;
  }
}

.upload-image-preview {
  position: relative;
  width: 124px;
  height: 124px;

  > img {
    width: 100%;
    height: 100%;
    border: transparent 2px solid;
    border-radius: 50%;

    @at-root .upload-image:hover {
      & {
        border-color: #ddd;
      }
    }
  }
}

.upload-image-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 18px;
  color: $secondary-inverse;
  background: var(--secondary-color);
  border-radius: 50%;
}
