@import '../../../variables.scss';

.footer {
  padding-bottom: 30px;

  .separator {
    margin-top: 0;
    margin-bottom: 16px;
  }

  .logo {
    width: 80px;
    max-width: 270px;
    margin-bottom: 2px;
    margin-left: 16px;
    margin-right: 8px;
  }

  .links {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: var(--primary-color);

    .external-link {
      color: var(--primary-color);
      margin: 4px 8px;
    }
  }

  .copyright {
    font-size: 14px;
    color: #8f9194;
  }

}


@media (max-width: 799px) {
  .footer {
    text-align: center;
    margin-top: 0px;
  }
}
