@import '../../variables.scss';

.message-bar {
  padding: 10px;

  &.primary {
    color: var(--background-inverse-color);
    background: var(--primary-color);
  }

  &.secondary {
    color: $secondary-inverse;
    background: var(--secondary-color);
  }

  &.danger {
    color: $danger-inverse;
    background: $danger-color;
  }

  &.success {
    color: $success-inverse;
    background: var(--success-color);
  }

  &.light {
    color: $light-inverse;
    background: $light-color;
  }

  &.dark {
    color: $dark-inverse;
    background: $dark-color;
  }

  &.fixed {
    text-align: center;
    position: fixed;
    z-index: $z-index-message;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  }

  &.top {
    top: 0;
  }

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  &.bottom {
    bottom: 0;
  }

  @media (max-width: $break-max) {
    text-align: center;
  }

  .close {
    float: right;
    background: none;
    border: none;
    cursor: pointer;
    color: #ffffff;
    font-weight: bold;
  }
}
