.post-title {
  margin-top: 8px;
}

.post-description {
  margin-top: 8px;
  margin-bottom: 16px;
}

.tags {
  margin: 16px 0px;
}

.poll {
  margin: 16px 0px;
}

.comment-box {
  margin-top: 8px;
}

.share-post-wrapper {
  margin-top: 8px;
  display: flex;
  justify-content: center;

  .share-post {
    width: 100%;
  }

  .share-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
