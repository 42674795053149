@import "../../variables.scss";

.reward-line {
  border-top: 1px solid $separator-color;
  margin: -1px -15px -15px;
  padding: 10px 15px;
  margin-top: 10px;
  color: var(--secondary-color);

  span {
    vertical-align: middle;
  }
}

.reward-icon {
  width: 24px;
  margin-right: 8px;
}


.task-info-row {
  display: flex;
  align-items: center;
}

.task-info-row-name {
  flex: 1;
  font-weight: 500;
  color: var(--secondary-color);
}

.task-info-row-value {
  color: $text-color;
}

.reward-modal-content {
  text-align: center;

  .reward-title {
    color: var(--secondary-color);
    font-weight: var(--bold);
    font-size: 18px;
  }
}
