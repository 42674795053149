@import '../../variables';
$fonts-dir: '../../assets/fonts';

@font-face {
  font-family: 'icomoon';
  src: url('#{$fonts-dir}/icomoon.eot?g3xxef');
  src: url('#{$fonts-dir}/icomoon.eot?g3xxef#iefix') format('embedded-opentype'),
    url('#{$fonts-dir}/icomoon.ttf?g3xxef') format('truetype'),
    url('#{$fonts-dir}/icomoon.woff?g3xxef') format('woff'),
    url('#{$fonts-dir}/icomoon.svg?g3xxef#icomoon') format('svg');
  font-weight: var(--normal);
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: var(--normal);
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.margin-left {
    margin-left: 10px;
  }

  &.margin-left-small {
    margin-left: 5px;
  }

  &.margin-right {
    margin-right: 10px;
  }

  &.primary {
    color: var(--primary-color);
  }

  &.secondary {
    color: var(--secondary-color);
  }

  &.dark {
    color: $dark-color;
  }

  &.white {
    color: $white-color;
  }

  &.warning {
    color: var(--warning-color);
  }

  &.facebook:before {
    content: "\e900";
  }
  &.instagram:before {
    content: "\e901";
  }
  &.linkedin:before {
    content: "\e902";
  }
  &.twitter:before {
    content: "\e903";
  }
  &.whatsapp:before {
    content: "\e904";
  }
  &.youtube:before {
    content: "\e905";
  }
  &.arrow-right:before {
    content: "\e906";
  }
  &.check:before {
    content: "\e907";
  }
  &.comment:before {
    content: "\e908";
  }
  &.help:before {
    content: "\e909";
  }
  &.sign-in:before {
    content: "\e90a";
  }
  &.time:before {
    content: "\e90b";
  }
  &.user:before {
    content: "\e90c";
  }
  &.down-arrow:before {
    content: "\e90d";
  }
  &.up-arrow:before {
    content: "\e90e";
  }
  &.comment-post:before {
    content: "\e90f";
  }
  &.share:before {
    content: "\e910";
  }
  &.envelop:before {
    content: "\e911";
  }
  &.attachment:before {
    content: "\e912";
  }
  &.publishing:before {
    content: "\e913";
  }
  &.member-ok:before {
    content: "\e914";
  }
  &.positive:before {
    content: "\e915";
  }
  &.negative:before {
    content: "\e916";
  }
  &.question:before {
    content: "\e917";
  }
  &.trophy:before {
    content: "\e918";
  }
  &.camera:before {
    content: "\e919";
  }
  &.floppy-disk:before {
    content: "\e962";
  }
  &.cancel-circle:before {
    content: "\ea0d";
  }
  &.lock:before {
    content: "\e91a";
  }
  &.forms:before {
    content: "\e91b";
  }
  &.search:before {
    content: "\e91c";
  }
  &.calendar:before {
    content: "\e91d";
  }
  &.triangle-alert:before {
    content: "\e91e";
  }
  &.previous:before {
    content: "\e91f";
  }
  &.next:before {
    content: "\e920";
  }
  &.back:before {
    content: "\e921";
  }
  &.ellipsis:before {
    content: "\e922";
  }
  &.eye:before {
    content: "\e923";
  }
  &.not-eye:before {
    content: "\e924";
  }
  &.arrow-up:before {
    content: "\e925";
  }

  &.connections:before {
    content: "\e926";
  }
  &.download:before {
    content: "\e927";
  }
  &.home:before {
    content: "\e928";
  }
  &.forms:before {
    content: "\e92a";
  }
  &.globe:before {
    content: "\e92b";
  }
  &.help:before {
    content: "\e92c";
  }
  &.history:before {
    content: "\e92d";
  }
  &.link:before {
    content: "\e92e";
  }
  &.logout:before {
    content: "\e92f";
  }
  &.feedback:before {
    content: "\e929";
  }
  &.more:before {
    content: "\e930";
  }
  &.post:before {
    content: "\e931";
  }
  &.ranking:before {
    content: "\e932";
  }
  &.rankings:before {
    content: "\e932";
  }
  &.social-accounts:before {
    content: "\e933";
  }
  &.survey:before {
    content: "\e934";
  }
  &.trails:before {
    content: "\e935";
  }
  &.list-empty:before {
    content: "\e936";
  }
  &.training:before {
    content: "\e937";
  }
  &.study:before {
    content: "\e938";
  }
  &.trail:before {
    content: "\e939";
  }
  &.step:before {
    content: "\e93a";
  }
  &.file:before {
    content: "\e93b";
  }
  &.times:before {
    content: "\e93c";
  }
  &.edit:before {
    content: "\e93d";
  }
  &.add:before {
    content: "\e93e";
  }
  &.metlife:before {
    content: "\e93f";
  }
}
