@import '../../../variables.scss';

.public-banner {
  width: 100%;
  min-height: 240px;
  height: 90%;
  padding: 30px 0;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  background: url('../../../assets/images/login-bg.jpg') center no-repeat;
  background-size: cover;

  & > .container {
    height: 180px;
  }

  .row {
    height: 100%;

    .col:first-child {
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      span {
        margin-left: 32px;
      }
    }
  }

  @media (max-width: 899px) {
    padding-top: 300px;

    .col {
      & > span {
        display: none;
      }
    }
  }
}
