@import '../../variables.scss';

.nmb {
  margin-bottom: 0;
}

.comment-box-title {
  color: $dark-color;
}

.removed-message {
  font-style: italic;
  color: var(--secondary-text-color) !important;
  font-size: 14px !important;
}

.comment-buttons {
  margin-top: 15px;

  .icon {
    color: var(--primary-color);
    font-weight: var(--bold);
  }

  .counter {
    margin-left: 6px;
    color: var(--primary-color);
  }

  .comments-separator {
    display: inline-block;
    height: 32px;
    width: 1px;
    background-color: #dedede;
    vertical-align: middle;
    margin: 0 13px;
  }
}
